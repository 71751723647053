import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Service1 from "../../components/UIUXDesign";
import Service2 from "../../components/SeoSocial";

gsap.registerPlugin(useGSAP);

const Services = () => {
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      <Service1 />
      <Service2 />
    </div>
  );
};

export default Services;
