import { connectarun } from "../../assets";
import Section from "../Section";

const Connect = () => {
  return (
    <Section className="bg-[#456dff]">
      <div className="container">
        <div className="flex justify-between items-center">
          <div className="relative z-1 max-w-[50rem]">
            <h2 className="h2 mb-[1rem] md:mb-10 lg:mb-[1.4rem]">
              Get a 30 minutes <span className="text-[#000]">consultation</span>
            </h2>

            <div className="flex-auto flex-wrap gap-x-2">
              <p className="leading-8 text-[#fff]">
                Thank you for being here. To discuss about your business
                objectives, a new campaign, creative project and how we could
                help you. Speak to Arun Mandru, our Creative Director.
              </p>
            </div>
          </div>

          <div className="flex relative z-2 p-5 md:p-0 text-center">
            <div className="flex-row gap-2 mb-10">
              <div className="block relative p-0.5">
                <div className="relative z-2 flex flex-col pointer-events-none">
                  <img
                    src={connectarun}
                    width={180}
                    height={180}
                    className="h-full object-cover rounded-full"
                  />
                </div>
              </div>
              <div className="flex-auto flex-wrap gap-x-2 mt-3">
                <h5 className="h5">Arun Mandru</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Connect;
