import { recentproject1, recentproject2, recentproject3 } from "../assets";
import Section from "./Section";

const ContactUs = () => {
  return (
    <Section id="contact">
      <div className="container relative">
        <div className="relative z-1 max-w-[50rem] mr-auto text-left mb-[3.875rem] md:mb-20 lg:mb-[6.25rem]">
          <h1 className="h1 mb-6">
            Get in
            {` `}
            <span className="relative text-[#6e9bff]">touch</span>
          </h1>
          <h3 className="h5 font-light mb-5">Send us a message</h3>
          <p className="body-1 font-light mx-auto mb-6 text-n-2 lg:mb-8">
            We’d love to hear from you. Here’s how you can reach us…
          </p>

          <div className="flex flex-auto flex-wrap gap-x-2 pt-5">
            <div>
              <h6 className="h6 mb-3 font-light">Address</h6>
              <p className="leading-8">
                5M, 126, Lal Bahadur Nagar, East of NGEF Layout,
                <br /> Kasturi Nagar, Bengaluru Urban, Karnataka 560043. India.
              </p>
            </div>
          </div>

          <div className="flex flex-auto flex-wrap gap-x-2 pt-5">
            <div>
              <h6 className="h6 mb-3 font-light">Phone</h6>
              <p className="leading-8">(+91) 9739793671</p>
            </div>
          </div>

          <div className="flex flex-auto flex-wrap gap-x-2 pt-5">
            <div>
              <h6 className="h6 mb-3 font-light">Email</h6>
              <p className="leading-8">hello@choruscom.com</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default ContactUs;
