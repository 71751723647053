import Section from "./Section";
import Button from "./Button";

const JoinChorus = () => {
  return (
    <Section className="bg-[#000000]">
      <div className="relative z-1 max-w-[50rem] mx-auto text-center mb-[1rem] md:mb-10 lg:mb-[2.25rem]">
        <h2 className="h2 text-center">
          Join <span className="text-[#6E9BFF]">Chorus</span>
        </h2>
      </div>

      <div className="container w-screen relative mx-auto justify-center">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
              <Button href="#application" white className="p-5 mt-10">
                Apply Now
              </Button>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Junior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 0-3 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
              <Button href="#application" white className="p-5 mt-10">
                Apply Now
              </Button>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">Senior Business Developer</h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Identify and research potential clients <br />
                Develop and maintain client relationships
                <br />
                Coordinate with sales teams to develop mutually beneficial
                proposals
                <br />
                Negotiate contract terms with clients and communicate with
                stakeholders
              </p>
              <Button href="#application" white className="p-5 mt-10">
                Apply Now
              </Button>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">Junior Business Developer</h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 0-3 Yrs of Experience
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Identify and research potential clients <br />
                Develop and maintain client relationships <br />
                Coordinate with sales teams to develop mutually beneficial
                proposals <br />
                Negotiate contract terms with clients and communicate with
                stakeholders
                <br />
                Determine and run marketing campaigns
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#456DFF]">
            <div className="flex flex-col py-10 px-10">
              <h4 className="text-lg text-white">
                Senior Motion Graphic Designer
              </h4>
              <p className="text-sm text-white mt-4">
                Full Time
                <br /> 3-6 Yrs of Experience
              </p>
              <p className="text-sm text-black mt-4">Skilled in</p>
              <p className="text-sm text-white mt-4">
                Adobe after effects <br />
                Video creation in 'Canva'
                <br />
                Adobe Illustrator ( Beginner Level )<br />
                Adobe Photoshop ( Beginner Level )<br />
                Basic Film Making
              </p>

              <p className="text-sm text-white mt-4">Job Role Description</p>

              <p className="text-sm text-white mt-4">
                Ideate the visual Treatment
                <br />
                Prepare the rough sketch of the video storyline
                <br />
                Create the graphics and collect visuals
                <br />
                Create video following the brand Guidelines
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default JoinChorus;
