import React from "react";
import Section from "../Section";
import { socials } from "../../constants";

const Footer = () => {
  return (
    <Section crosses className="footer !px-0 !py-10">
      <div className="container sm:justify-between justify-center items-center gap-10 max-sm:flex-col">
        <div className="flex relative z-2 px-2 mx-auto justify-center lg:px-0">
          <div className="flex-col md:flex-row grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-5 lg:gap-10 mb-10">
            <div className="block relative p-0.5 md:max-w-[25rem]">
              <div className="flex flex-auto flex-wrap gap-x-2 pt-5">
                <h3 className="h5 mb-3">What we do</h3>
                <p className="leading-8 text-[#adadad]">
                  5M, 126, Lal Bahadur Nagar,
                  <br /> East of NGEF Layout, Kasturi Nagar,
                  <br /> Bengaluru Urban, Karnataka 560043. India.
                </p>
              </div>
            </div>

            <div className="block relative p-0.5 md:max-w-[25rem]">
              <div className="flex flex-col flex-initial gap-x-2 pt-5">
                <div>
                  <h3 className="h5 mb-3">Contact Us</h3>
                </div>
                <div>
                  <a className="block" href="mailto:hello@choruscom.com">
                    hello@choruscom.com
                  </a>
                  <a className="block" href="tel:9739793671">
                    +91 9739793671
                  </a>
                </div>
              </div>
            </div>

            <div className="block relative p-0.5 md:max-w-[25rem]">
              <div className="flex flex-auto flex-wrap gap-x-2 pt-5">
                <h3 className="h5 mb-3">Quicklinks</h3>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <p className="caption text-n-4 lg:block">
            © {new Date().getFullYear()}. All rights reserved.
          </p>

          <ul className="flex gap-5 flex-wrap">
            {socials.map((item) => (
              <a
                key={item.id}
                href={item.url}
                target="_blank"
                className="flex items-center justify-center w-10 h-10 border border-solid border-white rounded-full transition-colors hover:bg-n-6"
              >
                <img
                  src={item.iconUrl}
                  width={16}
                  height={16}
                  alt={item.title}
                />
              </a>
            ))}
          </ul>
        </div>
      </div>
    </Section>
  );
};

export default Footer;
