import {
  culture1,
  culture2,
  culture3,
  culture4,
  culture5,
  culture6,
  culture7,
  culture8,
  culture9,
  culture10,
} from "../assets";
import Section from "./Section";

const OurCulture = () => {
  return (
    <Section className="bg-[#282828]">
      <div className="relative z-1 max-w-[50rem] mx-auto text-center mb-[1rem] md:mb-10 lg:mb-[2.25rem]">
        <h2 className="h2 text-center">
          Our <span className="text-[#6E9BFF]">Culture</span>
        </h2>
      </div>

      <div className="container w-screen relative mx-auto justify-center">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-5">
          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-center h-14">
                <img
                  src={culture1}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We <strong>LOVE</strong>
                <br />
                what we <strong>DO</strong>
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className="h-14">
                <img
                  src={culture2}
                  width={380}
                  height={380}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We <strong>FIRMLY</strong> believe in the POWER OF{" "}
                <strong>TEAMWORK.</strong>
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className="h-14">
                <img
                  src={culture3}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We think <strong>CLARITY</strong> is extremely important. We
                never assume.
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-cente h-14">
                <img
                  src={culture4}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We dig to the core of the <strong>PROBLEM</strong> & discover{" "}
                <strong>PERFECT SOLUTION.</strong>
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-center h-14">
                <img
                  src={culture5}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We <strong>THINK</strong> and <strong>ACT</strong> like{" "}
                <strong>PROBLEM SOLVERS.</strong>
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-cente h-14">
                <img
                  src={culture6}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We LEARN and PURSUE EXCELLENCE consistently.
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-center w-14 h-14">
                <img
                  src={culture7}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We ACCEPT, ADAPT, CORRECT & CHALLENGE ourselves.
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-center h-14">
                <img
                  src={culture8}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We believe TRUTH leads the HAPPINESS.
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-cente h-14">
                <img
                  src={culture9}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We are ENABLERS not grumblers.
              </p>
            </div>
          </div>

          <div className="my-5 grid__item place-items-center bg-[#6e9bff]">
            <div className="flex flex-col justify-center items-center py-10 px-5">
              <div className=" justify-center h-14">
                <img
                  src={culture10}
                  width={380}
                  height={362}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-lg text-white text-center mt-4">
                We belive in QUALITY over make believe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default OurCulture;
