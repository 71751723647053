import Section from "./Section";
import Accordian, { AccordianItem } from "./Accordion";

const Faq = () => {
  return (
    <Section className="what-we-do bg-[#282828]">
      <div className="container">
        <div className="relative">
          <div className="relative z-1 max-w-[50rem] text-left mb-[3.875rem] md:mb-10 lg:mb-[2.25rem]">
            <h2 className="h2 mb-6">
              Frequently Asked <span className="text-[#6E9BFF]">Questions</span>
            </h2>
          </div>

          <div>
            <div className="flex-col md:flex-row grid grid-cols-1 gap-2 md:gap-5 lg:gap-10 mb-10">
              <div className="block relative p-0.5">
                <div className="p-4 rounded-lg">
                  <Accordian className="">
                    <AccordianItem
                      value="1"
                      trigger="What is a branding & communication agency?"
                    >
                      A branding and communication agency is a company that
                      specializes in helping businesses develop and manage their
                      brand identity, as well as their communication strategies.
                      These agencies typically offer a range of services aimed
                      at building and promoting a brand's image, including brand
                      strategy development, logo and visual identity design,
                      messaging and positioning, advertising campaigns, digital
                      marketing, public relations, social media management, and
                      more.
                      <br />
                      The primary goal of a branding and communication agency is
                      to help clients create a strong and cohesive brand image
                      that resonates with their target audience, differentiates
                      them from competitors, and ultimately drives customer
                      engagement and loyalty. By understanding a client's
                      values, target market, and business objectives, these
                      agencies develop customized strategies and creative
                      solutions to effectively convey the brand's message and
                      personality across various channels and touchpoints.
                    </AccordianItem>

                    <AccordianItem value="2" trigger="What is Branding?">
                      Branding is the deliberate effort to establish a distinct
                      identity and perception for a product, service, or
                      organization. It encompasses defining the brand's values,
                      personality, and positioning relative to competitors.
                      Through consistent visual elements, messaging, and
                      experiences, branding aims to create emotional connections
                      with consumers, build trust, and foster loyalty. It's a
                      strategic process essential for shaping how a brand is
                      perceived and remembered.
                    </AccordianItem>

                    <AccordianItem
                      value="3"
                      trigger="What is 360 degree communication?"
                    >
                      360-degree communication, also known as integrated
                      marketing communication (IMC), refers to a comprehensive
                      approach where various communication channels and tools
                      are strategically utilized to convey a consistent message
                      to a target audience. This approach ensures that all
                      aspects of communication, including advertising, public
                      relations, digital marketing, social media, direct
                      marketing, and personal selling, work together
                      harmoniously to reinforce the brand message and achieve
                      marketing objectives.
                      <br />
                      The term "360 degrees" implies that communication efforts
                      encompass all possible touchpoints where a brand interacts
                      with its audience, both online and offline. By integrating
                      multiple channels and ensuring consistency in messaging
                      and branding across them, 360-degree communication aims to
                      create a cohesive and immersive brand experience for
                      consumers. This approach recognizes that modern consumers
                      engage with brands through diverse platforms and channels,
                      and it seeks to reach them at every relevant point of
                      contact to maximize impact and effectiveness.
                    </AccordianItem>

                    <AccordianItem
                      value="4"
                      trigger="What are the services provided by a branding and communication agency?"
                    >
                      A branding and communication agency provides services such
                      as brand strategy, logo design, messaging development,
                      advertising campaigns, digital marketing, public
                      relations, social media management, creative design,
                      market research, and brand monitoring.
                    </AccordianItem>

                    <AccordianItem
                      value="5"
                      trigger="How a Branding & Communication agency can help my business?"
                    >
                      A branding and communication agency can help your business
                      by developing a strong brand identity, creating effective
                      marketing strategies, increasing brand awareness, engaging
                      with your target audience, and ultimately driving growth
                      and success.
                    </AccordianItem>

                    <AccordianItem
                      value="6"
                      trigger="Can 'Chorus' be one stop solution for my design requirements?"
                    >
                      We function as an extended creative communication team
                      dedicated to your brand. With our expertise in ideation,
                      execution, and delivering campaigns and communication
                      collateral, we ensure a distinctive and memorable brand
                      identity through our deep understanding of building a
                      brand identity thorough consitancy in visual aesthetics
                      and tone of voice.
                    </AccordianItem>

                    <AccordianItem
                      value="7"
                      trigger="Does 'Chorus' have collaborators for marketing?"
                    >
                      We partner with Lmntree (brand strategy agency) to
                      strategize and implement brand building and marketing
                      initiatives.
                    </AccordianItem>

                    <AccordianItem
                      value="8"
                      trigger="What is a Retainership association?"
                    >
                      "Retainership" typically refers to a contractual agreement
                      between a client and a service provider where the client
                      pays a fee to retain the services of the provider for a
                      specified period. This fee is often paid upfront or on a
                      regular basis and ensures that the service provider is
                      available to the client for a set number of hours or
                      projects during the retainer period.
                    </AccordianItem>
                  </Accordian>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Faq;
