import Benefits from "../components/Benefits";
import Logic from "../components/Logic";
import WhatWeDo from "../components/WhatWeDo";
import RecentProjects from "../components/RecentProjects";
import Hero from "../components/Hero";

const Home = () => {
  return (
    <>
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <Hero />
        <Benefits />
        <Logic />
        <WhatWeDo />
        <RecentProjects />
      </div>
    </>
  );
};

export default Home;
