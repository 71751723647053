import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import ConnectUs from "../components/Common/ConnectUs";

const Root = () => {
  return (
    <>
      <Header />
      <Outlet />
      <ConnectUs />
      <Footer />
    </>
  );
};

export default Root;
