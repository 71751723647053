import { createBrowserRouter } from "react-router-dom";
import Root from "../Layout/Root";
import Home from "../Pages/Home";
import About from "../Pages/About";
import Services from "../Pages/Services/services";
import Works from "../Pages/Works/works";
import Contact from "../Pages/Contact/Contact";
import JoinChorus from "../components/JoinChorus";
import JoinUs from "../Pages/Joinus/Joinus";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/works",
        element: <Works />,
      },
      {
        path: "/join-us",
        element: <JoinUs />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
    ],
  },
]);
