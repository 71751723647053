"use client";

import React from "react";
import LatestCases from "../../components/LatestGallery";
import "./../Works/works.css";
import { Helmet } from "react-helmet";

const Works = () => {
  return (
    <>
      <body className="inner-pages-style1 portfolio-pg-style1">
        <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
          <LatestCases />
        </div>
      </body>
    </>
  );
};

export default Works;
