import { AboutBg } from "../assets";
import Button from "./Button";
import Section from "./Section";

const AboutHero = () => {
  return (
    <Section
      className="pt-[12rem] -mt-[5.25rem] min-h-90 h-full md:h-100 xl:py-15"
      crosses
      crossesOffset="lg:translate-y-[2.25rem]"
      id="hero"
    >
      <div
        className="absolute top-0 bottom-0 right-0 w-full bg-cover bg-no-repeat min-h-90 bg-gray-900 opacity-80 h-full"
        style={{
          backgroundImage: `url(${AboutBg})`,
        }}
      ></div>
      <div className="container relative">
        <div className="relative z-1 max-w-[47rem] mr-auto text-left mt-15 mb-[3.875rem] md:mb-20 lg:mb-[6.25rem]">
          <h1 className="h3 mb-6">
            A Chorus of Brand
            {` `}
            <span className="relative text-[#6e9bff]">Builders Marketing</span>
          </h1>
          <p className="body-1 font-light mx-auto mb-6 text-n-2 lg:mb-8">
            We are a team of strategists, creatives minds that share ideas,
            cross boundaries, break barriers, lead projects, take
            responsibilities, take risks to take your brand ahead.
            <br /> <br />
            We love emerging technology and we are keen observers of human
            behaviour, the we believe, help and motivate us to pursue what we
            do. We hold experience in the tech, pharmaceutical, FMCG, and
            fashion industries. <br /> <br />
            We have achieved the status, ‘one-stop agency for brand building &
            strategy’ with our unique approach and process. We help you define
            distinct brand identity, Conceive and execute advertising creatives
            through the blend of strategy and meticulous craftsmanship.
          </p>
          <Button href="/contact" white className="p-5">
            Get Started
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default AboutHero;
