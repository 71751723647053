import React from "react";
import ContactUs from "../../components/ContactUs";

const Contact = () => {
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      <ContactUs />
    </div>
  );
};

export default Contact;
