import React from "react";
import AboutHero from "../../components/AboutHero";
import OurCulture from "../../components/OurCulture";
import OurTeam from "../../components/OurTeam";
import AboutWeDo from "../../components/AboutWhatWeDo";
import Faq from "../../components/Faq";

const About = () => {
  return (
    <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
      <AboutHero />
      <OurCulture />
      <Faq />
      <AboutWeDo />
      <OurTeam />
    </div>
  );
};

export default About;
